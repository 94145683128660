import { 
    LockOutlined
  } from '@ant-design/icons';
  import { APP_PREFIX_PATH } from 'configs/AppConfig'
  
const dashBoardNavTree = [
    {
    key: 'listlocks',
    path: `${APP_PREFIX_PATH}/listlocks`,
    title: 'Registered Locks',
    icon: LockOutlined,
    breadcrumb: false,
    submenu: []
    }  
]
  
const navigationConfigSupport = [
    ...dashBoardNavTree
]
  
export default navigationConfigSupport;
  