import {
  UserAddOutlined,
  LockOutlined,
  SearchOutlined,
  UsergroupDeleteOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [
  {
    key: 'main',
    path: `${APP_PREFIX_PATH}/main`,
    title: 'User Management',
    icon: UsergroupDeleteOutlined,
    breadcrumb: false,
    submenu: [],
    role: ['admin'],
    hide: true
  },
  {
    key: 'userdetail',
    path: `${APP_PREFIX_PATH}/userdetail`,
    title: 'Create User',
    icon: UserAddOutlined,
    breadcrumb: true,
    submenu: [],
    role: ['admin'],
    props : {mode :'CREATE'},
    hide: true
  },
  {
    key: 'userupdate',
    path: `${APP_PREFIX_PATH}/userupdate`,
    title: 'Edit User',
    icon: UserAddOutlined,
    breadcrumb: true,
    submenu: [],
    role: ['admin'],
    props : {mode :'EDIT'},
    hide: true
  },
  {
    key: 'createreservation',
    path: `${APP_PREFIX_PATH}/resmngmt`,
    title: 'menu.create_reservation',
    icon: PlusCircleOutlined,
    breadcrumb: false,
    submenu: [],
    role: ['admin']
  },
  {
    key: 'updatereservation',
    path: `${APP_PREFIX_PATH}/resmngmt`,
    title: 'menu.search_reservation',
    icon: SearchOutlined,
    breadcrumb: false,
    submenu: [],
    role: ['admin'],
    hide: true
  },
  {
    key: 'listlocks',
    path: `${APP_PREFIX_PATH}/listlocks`,
    title: 'menu.lock_management',
    icon: LockOutlined,
    breadcrumb: false,
    submenu: [],
    role: ['admin', 'key-controller']
  },
  {
    key: 'lockdetail',
    path: `${APP_PREFIX_PATH}/lockdetail`,
    title: 'Lock Details',
    icon: UserAddOutlined,
    breadcrumb: true,
    submenu: [],
    role: ['admin', 'key-controller'],
    hide: true
  },
]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
