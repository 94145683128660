import {
	FIRST_SIGNIN,
	SIGNIN,
	AUTHENTICATED,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
	SET_PROPERTY_ATTRIBUTE
} from '../constants/Auth';

const initState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: null,
	user: null,
	property: null
}

const auth = (state = initState, action) => {
	switch (action.type) {
		case AUTHENTICATED:
			return {
				...state,
				loading: false,
				redirect: '/',
				token: action.token
			}

		case SIGNIN:
			return {
				...state,
				loading: false,
				user: {
					username: action.payload.username,
					pool: {
						userPoolId: action.payload.pool.userPoolId,
						clientId: action.payload.pool.clientId
					},
					signInUserSession: action.payload.signInUserSession,
					attributes: action.payload.attributes
				}
			}

		case SET_PROPERTY_ATTRIBUTE:
			return {
				...state,
				property: action.payload
			}

		case FIRST_SIGNIN:
			return {
				...state,
				loading: false,
				user: action.payload
			}

		case SHOW_AUTH_MESSAGE:
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false,
			}

		case HIDE_AUTH_MESSAGE:
			return {
				...state,
				message: '',
				showMessage: false,
			}

		case SIGNOUT_SUCCESS:
			return {
				...state,
				token: null,
				user:null,
				redirect: '/',
				loading: false,
			}

		case SIGNUP_SUCCESS:
			return {
				...state,
				loading: false,
				token: action.token
			}

		case SHOW_LOADING:
			return {
				...state,
				loading: true
			}

		default:
			return state;
	}
}

export default auth
