import { createStore } from 'redux'; //, applyMiddleware, compose } from "redux";
import reducers from "../reducers";
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist';
// import createSagaMiddleware from "redux-saga";
// import rootSaga from "../sagas/index";

// const sagaMiddleware = createSagaMiddleware();
// const middlewares = [sagaMiddleware];
// const persistConfig = { key: 'root', storage};
const persistedReducer = persistReducer({
  key: 'root',
  storage,
  blacklist: ['storage', 'user.pool.storage', 'user.storage']
}, reducers);

function configureStore(preloadedState) {
  // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(persistedReducer, preloadedState) // , composeEnhancers(applyMiddleware(...middlewares)));

  // sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept("../reducers/index", () => {
      const nextRootReducer = require("../reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

const store = configureStore();

const m1 = { store, persistor: persistStore(store) };
export default m1;

